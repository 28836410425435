
.DialogOverlay {
  background-color: #808DB8;
  opacity: 0.8;
  position: fixed;
  inset: 0;
  animation: overlayShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
  z-index: 10;
}

.DialogContent {
  background-color: transparent;
  border-radius: 6px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  height: 75vh;
  max-width: 90vw;
  max-height: 217px;
  padding: 0px;
  animation: contentShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
  z-index: 11;

  @media (min-width: 0px) {
    max-height: 60%;
  }

  @media (min-width: 992px) {
    width: 80vw;
    max-height: 85vh;
  }
}

.DialogContent button {
  position: absolute;
  top: -72px;
  right: 0px;
  justify-content: center;
  align-items: center;
  padding: 0px;
  width: 40px;
  height: 40px;

  @media (min-width: 992px) {
    top: 0px;
    right: -64px;
  }
}

.DialogContent video {
  background-color: transparent;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}

.DialogContent:focus {
  outline: none;
}

.DialogTitle {
  margin: 0;
  font-weight: 500;
  font-size: 17px;
}

.DialogDescription {
  margin: 10px 0 20px;
  font-size: 15px;
  line-height: 1.5;
}

@keyframes overlayShow {
  from {
    opacity: 0.6;
  }
  to {
    opacity: 0.8;
  }
}

@keyframes contentShow {
  from {
    opacity: 0;
    transform: translate(-50%, -48%) scale(0.96);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
}